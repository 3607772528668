import {
  useEffect,
  useState,
} from "react";

import {
  API,
  Logger,
  graphqlOperation,
} from "aws-amplify";

const logger = new Logger("item-query", "INFO");

const GetItem = `query($url:String) {
      item(url:$url) {
        id
        compact
        url
        product {
          id
          title
          description
          image {
            id
            url
          }
        }
      }
    }`;

export function useItemQuery ({batchId, compactItemId}) {
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // TODO: make item URL configurable (?)
    const url = `https://0.vea.codes/${batchId}/${compactItemId}`;
    logger.info("sending query:", {batchId, compactItemId, url});
    API.graphql(graphqlOperation(GetItem, {url}))
      .then((result) => { logger.info(result); setItem(result.data.item); })
      .catch((err) => { logger.error(err); setError(err); })
    ;
  }, []);

  return ({
    item,
    error,
  });
}
