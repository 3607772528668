import React from "react";

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

import {
  useStyles,
} from "../lib/styles.js";

export default function ItemCard (props) {
  // TODO: pass styles in props?
  const classes = useStyles();
  const item = props.item;

  return (
    <Card>
      <CardActionArea>
        <CardMedia className={classes.itemmedia}
                   image={item?.product?.image?.url}
                   title={item?.product?.title}
        />

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item?.product?.title}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {item?.product?.description}
          </Typography>
        </CardContent>
      </CardActionArea>

      {/* <CardActions> */}
      {/*   <Button size="small" color="primary">Share</Button> */}
      {/*   <Button size="small" color="primary">Learn More</Button> */}
      {/* </CardActions> */}

    </Card>
  );
}
